import $ from "jquery";

$(document).on("turbolinks:load", () => {
  $("#main-nav-toggle").click((e) => {
    e.preventDefault();
    $("#main-nav").slideToggle(150).toggleClass("nav-main--show");
  });
  $(".nav-main__link--has-dropdown").click(function(e) {
    e.preventDefault();
    let isExpanded = $(this).attr('aria-expanded') == 'true'
    $(this).attr('aria-expanded', !isExpanded);
    $(this).toggleClass("nav-main__link--dropdown-active");
    $(this).next(".nav-submenu").slideToggle(120).toggleClass("nav-submenu--show");
  });
});

// Makes sure the navigation doesn't disappear when closed in mobile and screen
// is resized to desktop width.
const handleNavMediaQuery = (mql) => {
  if (mql.matches) {
    $(".nav-main").show();
  }
};

let mql = window.matchMedia("(min-width: 980px)");
mql.addListener(handleNavMediaQuery);
handleNavMediaQuery(mql);

